import qiye from '../../assets/img/qiye.png'
import qiye_ac from '../../assets/img/qiye_ac.png'
import caigou from '../../assets/img/caigou.png'
import caigou_ac from '../../assets/img/caigou_ac.png'
import neirong from '../../assets/img/neirong.png'
import neirong_ac from '../../assets/img/neirong_ac.png'
import yonghu from '../../assets/img/yonghu.png'
import yonghu_ac from '../../assets/img/yonghu_ac.png'
import guocheng from '../../assets/img/guocheng.png'
import guocheng_ac from '../../assets/img/guocheng_ac.png'
export const left_nav_data = [
{
    id: 'business_manage',
    key:1,
    auth_id: 'business_manage',
    indexName: 'businessManage',
    iconfont:"icon-qiyeguanli",
    icon: qiye,
    activeicon: qiye_ac,
    name: '企业管理',
    path: '/businessManage',
},
{
    id: 'purchase_manage',
    auth_id: 'purchase_manage',
    indexName: 'purchaseManage',
    iconfont:"icon-navicon-cggl",
    key:2,
    icon: caigou,
    activeicon: caigou_ac,
    name: '采购管理',
    expand: true,
    children:[
        {
            id: 'purchase_plan',
            auth_id: 'purchase_plan',
            parentKey:2,
            key:3,
            indexName: '1-1',
            icon: '',
            activeicon: '',
            name: '采购计划',
            path: '/purchaseManage/plan',
        },
        {
            id: 'purchase_auto_plan',
            auth_id: 'purchase_auto_plan',
            parentKey:2,
            key:4,
            indexName: '1-1',
            icon: '',
            activeicon: '',
            name: '自动采购规则配置',
            path: '/purchaseManage/autoplan',
        },
        {
            id: 'xinyu',
            auth_id: 'xinyu',
            parentKey:2,
            key:24,
            indexName: '1-1',
            icon: '',
            activeicon: '',
            name: '信誉度维护',
            path: '/purchaseManage/xinyu',
        },
    ]
},
{
    id: 'content_manage',
    auth_id: 'content_manage',
    indexName: 'contentManage',
    iconfont:"icon-neirongguanli",
    icon: neirong,
    key:5,
    activeicon: neirong_ac,
    name: '内容管理',
    expand: true,
    children:[
        {
            id: 'xuzhi',
            auth_id: 'xuzhi',
            indexName: '5-1',
            parentKey:5,
            key:51,
            icon: '',
            activeicon: '',
            name: '集采须知',
            path: '/contentManage/xuzhi',
        },
        {
            id: 'evaluation',
            auth_id: 'evaluation',
            indexName: '5-2',
            parentKey:5,
            key:52,
            icon: '',
            activeicon: '',
            name: '评价管理',
            path: '/contentManage/evaluation',
        },
        {
            id: 'materialAndModel',
            auth_id: 'materialAndModel',
            indexName: '5-3',
            parentKey:5,
            key:53,
            icon: '',
            activeicon: '',
            name: '材料型号维护',
            path: '/contentManage/materialAndModel',
        },
        {
            id: 'guanyujicai',
            auth_id: 'guanyujicai',
            indexName: '5-4',
            parentKey:5,
            key:54,
            icon: '',
            activeicon: '',
            name: '关于集采',
            path: '/contentManage/jicaiAbout',
        },
        {
            id: 'banner',
            auth_id: 'banner',
            indexName: '5-5',
            parentKey:5,
            key:55,
            icon: '',
            activeicon: '',
            name: '轮播图设置',
            path: '/contentManage/banner',
        },
    ]
},
{
    id: 'process_manage',
    auth_id: 'process_manage',
    indexName: 'processManage',
    iconfont:"icon-guochengguanli",
    key:6,
    icon: guocheng,
    activeicon: guocheng_ac,
    name: '过程管理',
    expand: true,
    children:[
        {
            id: 'demand_manage',
            auth_id: 'demand_manage',
            indexName: '3-1',
            parentKey:6,
            key:7,
            icon: '',
            activeicon: '',
            name: '需求管理',
            path: '/processManage/demand',
        },
        {
            id: 'quote_manage',
            auth_id: 'quote_manage',
            indexName: '3-2',
            parentKey:6,
            key:8,
            icon: '',
            name: '报价管理',
            path: '/processManage/quote',
        }
    ]
},
{
    id: 'user_manage',
    key:9,
    auth_id: 'user_manage',
    indexName: 'userManage',
    iconfont:"icon-yonghuguanli",
    icon: yonghu,
    activeicon: yonghu_ac,
    name: '用户管理',
    expand: true,
    children:[
        {
            id: 'user_list',
            parentKey:9,
            key:10,
            auth_id: 'user_list',
            indexName: '3-1',
            icon: '',
            activeicon: '',
            name: '用户列表',
            path: '/userManage/user',
        },
        {
            id: 'auth_config',
            parentKey:9,
            key:11,
            auth_id: 'auth_config',
            indexName: '4-2',
            icon: '',
            name: '权限配置',
            path: '/userManage/auth',
        }
    ]
},
]