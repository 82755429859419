<template>
  <div class="index">
    <div class="index-header">
      <Header />
    </div>
    <div class="index-main">
      <left-nav class="left-nav"></left-nav>
      <div class="main-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import leftNav from "../lef-nav/leftNav.vue";
import Header from  "../header/header.vue"
export default {
  name: "mainContent",
  data() {
    return {};
  },
  computed: {
  },
  components: {
    leftNav,Header,
  },
  created() {},
  mounted() {
    
  },
  methods: {
  },
  watch: {
  },
};
</script>

<style scoped>
</style>
<style lang="less" scoped>
.index {
  width: 100%;
  height: 100%;
  transform: width 0.3s;
  transition: 0.3s;
  .index-header{
    height: 60px;
    width: 100%;
    line-height: 50px;
  }
  .index-main{
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    .left-nav{
      width: 210px;
      height: 100%;
    }
    .main-content {
      width: calc(100% - 210px);
      height: 100%;
      animation: fold-in 0.5s 1;
      padding: 10px;
      padding-bottom: 0px;
      box-sizing: border-box;
    }
  }
}

@keyframes fold-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>

