<template>
  <div class="left-tab">
    <div v-for="(item, index) in left_nav_data" :key="index" class="item"
      :class="activeIndex === index ? 'active' : ''" @click="change_tab(index, item)"
    > 
    <template v-if="authComputedP(item,'parent')">
      <div class="item-pa">
         <!-- <img :src="activeIndex === index ? item.activeicon : item.icon" /> -->
         <i class="iconfont" :class="item.iconfont"></i>
         <span class="item-name">{{ item.name }}</span>
         <i v-if="item.children && item.children.length" class="icon el-icon-arrow-up" 
           :class="item.expand ? 'is-reverse' : ''" @click="expand_Item(item)"
         ></i>
      </div>
      
      <template v-if="item.children && item.expand">
        <div v-for="(child, childIndex) in item.children" :key="childIndex" class="child-item" 
          :class="activeChildIndex === childIndex && activeIndex === index ? 'active' : ''" @click.stop="change_childTab(childIndex, index, child)"
        >
        <template v-if="authComputedC(item.key, child.key)">
          {{ child.name }}
        </template>
        </div>
      </template>
    </template>
    </div>
  </div>
</template>

<script>
import { left_nav_data } from "./leftnav";

export default {
  name: "leftNav",
  data() {
    return {
      left_nav_data:[],
      activeIndex: 0,
      activeChildIndex: 0,
      userinfo: JSON.parse(sessionStorage.userInfo),
      authArr:[]
    };
  },
  computed: {},
  components: {},
  created() {
    this.left_nav_data = this.$deepClone(left_nav_data);
  },
  mounted() {
    this.router_init()
    this.authInit()
  },
  methods: {
    // 权限配置
    authComputedP(item){
      const authKey = item.key
      if(item.children){
        let flag = false;
        for(let child of item.children){
          if(this.authArr.find(v=> v==child.key)){
            flag = true
            break
          }
        }
        return this.authArr.find(v=> v==authKey) && flag
      }else{
        return this.authArr.find(v=> v==authKey)
      }
    },
    // 权限配置
    authComputedC(pKey, childK){
      return this.authArr.find(v=> v==pKey) && this.authArr.find(v=> v==childK)
    },
    authInit(){
      this.authArr = this.userinfo.roleSource.split(",")
    },
    change_tab(index, item) {
      this.activeIndex = index;
      this.go_router(item.path || item.children[0].path)
    },
    change_childTab(index, parentIndex, item) {
      this.activeChildIndex = index;
      this.activeIndex = parentIndex;
      this.go_router(item.path)
    },
    go_router(path){
      let currentPath = this.$route.path;
      if(path !== currentPath){
        this.$router.push(path)
      }
    },
    expand_Item(item) {
      item.expand = !item.expand
    },
    router_init(){
      let currentPath = this.$route.path;
      let indexName = currentPath.split("/")[1];
      let activeIndex = 0
      for(let i=0; i<this.left_nav_data.length; i++ ){
        let item = this.left_nav_data[i]
        if(item.indexName === indexName){
          activeIndex = i;
          break
        }
      }
      this.activeIndex = activeIndex;
    }
  },
  watch: {
    $route: function () {
    },
  },
};
</script>
<style lang="less" scoped>
.left-tab{
  width: 100%;
  height: 100%;
  z-index: 1;
  padding-top: 10px;
  box-sizing: border-box;
  color: #BBC4D1;
  color: #fff;
  color: #000;
  background: #3A3D48;
  background: #EFEFF0;
  font-size: 16px;
  .item {
    margin: 10px 0;
    line-height: 40px;
    cursor: pointer;
    text-align: left;
    padding: 0 10px;
    box-sizing: border-box;
    .item-pa{
      display: flex;
      align-items: center;
    }
    .item-name{
      margin-left: 5px;
    }
    img{
      margin-right: 10px;
      margin-left: 10px;
      width: 20px;
      height: 20px;
      display: inline-block;
    }
    .icon{
      margin-left: 20px;
      transition: transform 500ms;
    }
    .is-reverse{
      transform: rotateZ(180deg);
    }

    .child-item {
      box-sizing: border-box;
      background: #303B45;
      background: #EFEFF0;
      padding-left: 50px;
      color: #BBC4D1;
      color: #000;
    }

    .active {
      color: #fff;
      color: #000;
      color: #589EF8;
    }
  }

  .active {
    color: #fff;
    color: #000;
    color: #589EF8;
  }
}
</style>
