<template>
    <div class="common-head">
        <div class="title">
            <img class="logo" :src="logo" alt="">
            {{name}}
        </div>
        <div class="right-user">
           <div class="img" >
                <el-dropdown class="img-touxiang" :title="userName"  @command="handleCommand">
                    <div class="content-box" @click.stop="show_exit">
                        <div class="user-img"><img src="../../assets/img/touxiang.png"/></div>
                        <div class='user-name'>{{userName}}</div>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                       <el-dropdown-item command="exit">退出</el-dropdown-item>
                       <el-dropdown-item command="pass">修改密码</el-dropdown-item>
         
                    </el-dropdown-menu>
                    <transition name="exit-fade">
                        <div class="" v-if="show">
                            <div class="exit" @click="handleCommand">
                               退出
                               <div class="sanjiao"></div>
                            </div>
                            <div class="exit" @click="goPass">
                               修改密码
                            </div>
                        </div>
                    </transition>  
                </el-dropdown>  
            </div>
        </div>
    <newAndEdit :formData="formData" :show.sync="formShow" :title="formTitle" @saveData="newAndEditSave"></newAndEdit>
    </div>
</template>
<script>
import logo from "../../assets/img/logo-white.png"
import {ajax_post_userPass } from "../../api/index"
export default {
    name:"commonHead",
    data(){
        return {
            logo,
            name: "外加剂原材料集中采购平台",
            userName: JSON.parse(sessionStorage.userInfo || "{}").userName || "用户001",
            show:false,
            userInfo: JSON.parse(sessionStorage.userInfo || '{}'),
            formDataPass:[
              {
                id: "passWord",
                name: "密码",
                value: '',
                type: "input",
                check_type: 'required',
                placeholder: "请填写密码",
                unit: '',
                slot: "",
                options: [],
                show:true,  
              }
            ],
            passFlag: false, // 修改密码
            formData:[],
            formShow: false,
            editId:"",
            formTitle:"修改密码",
        }
    },
    mounted(){
        let vue = this;
        let body = document.querySelector("body");
        body.onclick = function() {
            vue.show = false;
        };
    },
    methods:{
        show_exit(){
            this.show = true;
        },
        handleCommand(command) {
            switch(command){
                case "exit":
                    this.$Confirm({
                       title:'<div> </div>',
                       info:'确定退出系统',
                       exit:true,
                       cancelText:'',
                    }).then(()=>{
                           sessionStorage.removeItem('userInfo');
                           this.$router.push("/");
                
                    })    
                    break
                case "pass":
                   this.goPass(this.userInfo)
                   break
            }
        },
        goPass(data){
          this.formData = this.$deepClone(this.formDataPass)
          this.formShow = true;
          this.passFlag = true;
          this.editId = data.id
        },
        newAndEditSave(){
          if(this.passFlag){
            this.updatePass()
            return
          }
        },
        updatePass(){
          if(this.formData[0].value === ""){
            this.$message.warning("请填写密码")
            return 
          }
          const params = {
            id: this.editId,
            passWord: this.formData[0].value
          }
          ajax_post_userPass(params).then(res=>{
            if(res.code == 200){
              this.$message.success("操作成功")
              this.formShow = false;
            }
          }).catch(err=>{
            console.log("ajax_err:",err)
          })
        }
    }
}
</script>
<style lang="less" scoped>
.common-head{
    padding: 0 20px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    background: #24262E;
    background: #589EF8;
    color: #fff;
    .title{
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
        .logo{
            display: inline-block;
            height: 30px;
            width: 100px;
            margin-right: 10px;
        }
    }
    .right-user{
        .img{
            line-height:50px;
            // width: 100px;
            height: 50px;
            position: relative;
            
            .content-box{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
            }
            .img-touxiang{
                width: 100%;
                height: 100%;
                display: block;
                .user-img{
                    width: 38px;
                    height: 38px;
                    float: left;
                    border-radius: 50%;
                    overflow: hidden;
                    img{
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                    }
                }
                .user-name{
                    float: left;
                    font-size: 13px;
                    color: #fff;
                    margin-left:10px; 

                }
            }
            .exit-bac{
                position: fixed;
                z-index: 6999;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                overflow: auto;
                margin: 0;
                // display: none;
                justify-content: center;
                align-items: center;
                background: rgba(0, 0, 0, 0.7);
                .exit{
                     position: absolute;
                     width: 80px;
                     height: 35px;
                     top:60px;
                     right:10px;
                     background: #000;
                     line-height: 35px;
                     border-radius: 3px;
                     cursor: pointer;
                     color: #fff;
                     .sanjiao{
                         position: absolute;
                         top:-3px;
                         left:50%;
                         right:50%;
                         margin:0 auto;
                         width: 0;
                         height: 0;
                         border-bottom: 5px solid #000;
                         border-right: 5px solid transparent;
                         border-left: 5px solid transparent;
                    }
                }
            }
        }
    }
}
</style>